import React from 'react'

export const DocumentationView = () => {
  return (
    <div>
      <div className='mb-4'>
        <h3><i className='fa fa-file-text' /> Documentación</h3>
      </div>
    </div>
  )
}