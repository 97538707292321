import React from 'react'

export const EventsView = () => {
  return (
    <div>
      <div className='mb-4'>
        <h3><i className='fa fa-clock' /> Eventos</h3>
      </div>
    </div>
  )
}